














import { Vue, Component, Prop } from 'vue-property-decorator';
import Button from '@/shared/resources/components/buttons/Button.vue';

@Component({
  components: {
    Button,
  },
})
export default class DashboardShortcutButton extends Vue {

}
